<template>
    <div class="relative">
      <div class="w-full absolute block z-50">
        <Header/>
      </div>
      <div class="w-screen products-section-container space-top lavish-products-banner">
        <div class="relative flex items-center justify-center mobileL:gap-4 xl:gap-16 mx-auto">
          <img src="../../assets/images/product-page/lavish-product-1.png" class="relative mobileL:mb-10 w-8/12 mobileL:w-1/2 xl:w-auto" alt="Lavish Lashes Banner">
          <div class="items-center flex-col z-10 text-center mr-10 mt-32 -ml-6 mobileL:mt-0 mobileL:mr-0">
            <h1 class="font-historia text-right mobileL:text-center text-4xl mobileL:text-5xl xl:text-6xl 2xl:text-9xl
            text-yellow-2 leading-tight tracking-wider">
              Skin Calming <br> Hydragel
            </h1>
            <h2 class="font-historia text-2xl hidden mobileL:block mobileL:text-3xl lg:text-4xl xl:text-6xl 2xl:text-6xl text-white-1 mt-7 tracking-wide leading-none">
              Now available in stores
            </h2>
          </div>
        </div>
        <h2 class="font-historia text-3xl block mobileL:hidden mx-auto text-center text-white-1 tracking-wide">
          Now available in stores
        </h2>
      </div>
      <div class="w-full absolute block z-20">
        <Footer/>
      </div>
    </div> 
</template>
  
<script>
import Header from '../../components/navigation/LavishHeader.vue'
import Footer from '../../components/footer/LavishFooter.vue'

export default {
  components: {
    Header,
    Footer
  },
}
</script>
  
<style scoped>
.products-section-container {
  height: 70vh;
}

.space-top {
  margin-top: 90px;
}



@media (max-width: 480px) {
  .space-top {
    margin-top: 60px;
  }
  .products-section-container {
      height: 60vh;
  }
}

@media (max-width: 380px), (max-height: 800px) {
  .products-section-container {
      height: 70vh;
  }
}

@media (min-width: 480px) {
  .lash-sin-bg {
  background: radial-gradient(#ED7A97 -0%, #fef6ef 75%);
  border-radius: 10px;
  }
}

.lavish-products-banner {
  background: url('../../assets/images/product-page/lavish-products-bg.png') no-repeat;
  background-size: 100% 100%;
  font-family: 'Kannada';
}

@media (min-width: 1280px) {
  .space-top {
    margin-top: 100px;
  }
  .products-section-container {
    height: calc(100vh - 100px);
  } 
}

@media (min-width: 1536px) {
  .space-top {
    margin-top: 128px;
  }

  .products-section-container {
    height: calc(100vh - 128px);
  }
}
</style>